import React from "react"

import Layout from "../components/layout"
import CoursesMainArea from "../components/CoursesMainArea"
import CoursesPage from "../components/CoursesPage"
import { graphql } from "gatsby"
import useLang from "../hooks/useLang"
import Arrow from "../components/Arrow"

const Courses = ({ data }) => {
  const language = useLang()
  const articales = data.allMarkdownRemark.edges.map(({ node }) => ({
    ...node.frontmatter,
    ...node.fields,
  }))

  return (
    <Layout title={language === "ar" ? "الدورات" : "Courses"} lang={language}>
      <CoursesMainArea
        title={language === "ar" ? "الدورات" : "Courses"}
        description=""
      />
      <div className="b-homepage-content">
        <div className="b-layer-main">
          <Arrow />
          <CoursesPage articles={articales} />
        </div>
      </div>
    </Layout>
  )
}
export default Courses

export const CoursesQuery = graphql`
  query($lang: String!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/courses/" }
        frontmatter: { lang: { eq: $lang }, draft: { ne: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            categories
            lang
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
